/* eslint-disable @typescript-eslint/camelcase */
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import {
  TopUpVerificationDetail,
  TopUpVerificationEntities
} from "@/domain/entities/TopupVerification";
import { Pagination } from "@/domain/entities/Pagination";
import { TopUpVerificationPresenter } from "../presenters/TopUpVerificationPresenter";
import { container } from "tsyringe";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import {
  RejectTopUpVerification,
  VerifyTopUpVerification
} from "@/data/payload/api/TopUpVerificationApiRequest";

export interface TopUpVerificationState {
  isLoading: boolean;
  isError: boolean;
  errorCause: string;
  topUpVerificationDetail: TopUpVerificationDetail;
  topUpVerificationList: TopUpVerificationEntities;
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "finance_top_up_verification"
})
class TopUpVerificationStore extends VuexModule
  implements TopUpVerificationState {
  public isLoading = false;
  public isError = false;
  public errorCause = "";
  public topUpVerificationDetail = new TopUpVerificationDetail();
  public topUpVerificationList = new TopUpVerificationEntities(
    new Pagination(1, 10),
    []
  );
  public isFirstRequest = true;
  public search = "";
  public advancedFilter: any = {
    status: "",
    partner: "",
    verifiedBy: "",
    bank: ""
  };

  @Action
  public fetchTopUpVerificationDetail(params: { id: any }) {
    this.setLoading(true);
    const presenter = container.resolve(TopUpVerificationPresenter);
    presenter
      .getTopUpVerificationDetail(Number(params.id))
      .then((res: TopUpVerificationDetail) => {
        this.setTopUpVerificationDetail(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }
  @Action
  public fetchTopUpVerificationList(params: {
    page: number;
    limit: number;
    search: string;
    partner: string;
    status: string;
    bank: string;
    verifiedBy: string;
  }) {
    this.setLoading(true);
    const presenter = container.resolve(TopUpVerificationPresenter);
    presenter
      .getTopUpVerificationList(
        params.page,
        params.limit,
        params.search,
        params.partner,
        params.status,
        params.bank,
        params.verifiedBy
      )
      .then((res: TopUpVerificationEntities) => {
        this.setTopUpVerificationList(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public async verifyTopUpVerification(params: { id: any; amount: number }) {
    const presenter = container.resolve(TopUpVerificationPresenter);
    return presenter
      .verifyTopUpVerification(
        new VerifyTopUpVerification(
          new TopUpVerificationDetail({
            id: params.id,
            amount: params.amount
          })
        )
      )
      .then((resp: ResponsePayload) => {
        return resp;
      })
      .catch((err: any) => {
        return new ResponsePayload({
          message: err.response ? "server" : "internet"
        });
      });
  }

  @Action
  public async rejectTopUpVerification(params: {
    id: any;
    reasonRejected: string;
  }) {
    const presenter = container.resolve(TopUpVerificationPresenter);
    return presenter
      .rejectTopUpVerification(
        new RejectTopUpVerification(
          new TopUpVerificationDetail({
            id: params.id,
            reasonRejected: params.reasonRejected
          })
        )
      )
      .then((resp: ResponsePayload) => {
        return resp;
      })
      .catch((err: any) => {
        return new ResponsePayload({
          message: err.response ? "server" : "internet"
        });
      });
  }

  @Action
  public async getListBank() {
    const presenter = container.resolve(TopUpVerificationPresenter);
    return presenter
      .getListBankName()
      .then(res => {
        return res;
      })
      .catch(() => {
        return [];
      });
  }

  @Action
  public async getListVerifier() {
    const presenter = container.resolve(TopUpVerificationPresenter);
    return presenter
      .getListVerifier()
      .then(res => {
        return res;
      })
      .catch(() => {
        return [];
      });
  }

  @Action
  public getTopUpVerificationList() {
    this.setFirstRequest(false);
    this.fetchTopUpVerificationList({
      page: this.topUpVerificationList.pagination.page,
      limit: this.topUpVerificationList.pagination.limit,
      search: this.search,
      partner: this.advancedFilter.partner.name || "",
      status: this.advancedFilter.status,
      verifiedBy: this.advancedFilter.verifiedBy,
      bank: this.advancedFilter.bank
    });
  }

  @Action
  public initDataTable() {
    this.setFirst();
    this.setFirstRequest(true);
    this.setSearch("");
    this.setAdvancedFilter({
      status: "",
      partner: "",
      verifiedBy: "",
      bank: ""
    });
    this.setError(false);
    this.setErrorCause("");
    this.fetchTopUpVerificationList({
      page: this.topUpVerificationList.pagination.page,
      limit: this.topUpVerificationList.pagination.limit,
      search: this.search,
      partner: this.advancedFilter.partner?.name || "",
      status: this.advancedFilter.status,
      verifiedBy: this.advancedFilter.verifiedBy,
      bank: this.advancedFilter.bank
    });
  }

  @Mutation
  public setError(status: boolean) {
    this.isError = status;
  }

  @Mutation
  public setLoading(status: boolean) {
    this.isLoading = status;
  }

  @Mutation
  public setErrorCause(err: string) {
    this.errorCause = err;
  }

  @Mutation
  public setTopUpVerificationDetail(data: TopUpVerificationDetail) {
    this.topUpVerificationDetail = data;
  }
  @Mutation
  public setTopUpVerificationList(data: TopUpVerificationEntities) {
    this.topUpVerificationList = data;
  }

  @Mutation
  public setAdvancedFilter(data: any) {
    this.advancedFilter = data;
  }

  @Mutation
  public setSearch(val: string) {
    this.search = val;
  }

  @Action
  public async searchAction(value: string) {
    await this.setSearch(value);
    await this.setFirstPage();
    await this.getTopUpVerificationList();
  }

  @Action
  public async setFirstPage() {
    this.setFirst();
  }

  @Mutation
  private setFirst() {
    this.topUpVerificationList.pagination.page = 1;
  }

  @Mutation
  public setFirstRequest(value: boolean) {
    this.isFirstRequest = value;
  }
}

export const TopUpVerificationController = getModule(TopUpVerificationStore);
