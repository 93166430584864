import { ChangeRequestInterface } from "../contracts/NotificationRequest";

/* eslint-disable @typescript-eslint/camelcase */
export class EditRouteApiRequest implements ChangeRequestInterface {
  routeId = 0;
  routeStatus = "";
  routeOption:
    | {
        groupOptionName: string;
        isActive: boolean;
      }[]
    | undefined = [];

  constructor(fields?: Partial<EditRouteApiRequest>) {
    Object.assign(this, fields);
  }

  public toJSON() {
    return JSON.stringify({
      route_id: this.routeId,
      route_status: this.routeStatus,
      route_options: this.routeOption
        ? this.routeOption.map(item => ({
            group_option_name: item.groupOptionName,
            is_active: item.isActive
          }))
        : []
    });
  }
}
