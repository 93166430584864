import { TopupApprovalRequestInterface } from "../contracts/TopupApprovalRequest";

/* eslint-disable @typescript-eslint/camelcase */
export class ApproveTopupApproval implements TopupApprovalRequestInterface {
  tum_id: number;
  amount: number;
  constructor(tum_id: number, amount: number) {
    this.tum_id = tum_id;
    this.amount = amount;
  }
  toJSON(): string {
    return JSON.stringify({
      tum_id: this.tum_id,
      amount: this.amount
    });
  }
}

export class RejectTopupApproval implements TopupApprovalRequestInterface {
  tum_id: number;
  reject_reason: string;
  constructor(tum_id: number, reject_reason: string) {
    this.tum_id = tum_id;
    this.reject_reason = reject_reason;
  }
  toJSON(): string {
    return JSON.stringify({
      tum_id: this.tum_id,
      reject_reason: this.reject_reason
    });
  }
}
