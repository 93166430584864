import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";

import store from "@/store";
import { container } from "tsyringe";
import { Pagination } from "@/domain/entities/Pagination";
import {
  ActorData,
  TopupApprovalData,
  TopupApprovalListEntities
} from "@/domain/entities/TopupApproval";
import { TopupApprovalPresenter } from "../presenters/TopupApprovalPresenter";
import {
  ApproveTopupApproval,
  RejectTopupApproval
} from "@/data/payload/api/TopupApprovalApiRequest";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";

export interface TopupApprovalState {
  isLoading: boolean;
  isError: boolean;
  errorCause: string;
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "topup-approval"
})
class TopupApprovalStore extends VuexModule implements TopupApprovalState {
  public isLoading = false;
  public isLoadingDetail = false;
  public isError = false;
  public errorCause = "";
  public isErrorDetail = false;
  public errorCauseDetail = "";
  public isLoadingApprove = false;
  public isSuccessApprove = false;
  public isLoadingReject = false;
  public isSuccessReject = false;
  public search = "";
  public advanceFilterData = {
    clientPartnerName: "",
    topupStatus: { name: "", value: "" },
    bankName: { name: "", value: "" },
    verificationTopupBy: { name: "", value: "" }
  };
  public topupApprovalList = new TopupApprovalListEntities(
    new Pagination(1, 10),
    []
  );
  public actorVerifiedList: Array<ActorData> = [];
  public showTopupApprovalForm = false;
  public showTopupRejectionForm = false;
  public isShowDetail = false;
  public detailData: TopupApprovalData = new TopupApprovalData();

  @Action
  public approveTopupApproval(params: { id: number; amount: number }) {
    this.setLoadingApprove(true);
    const presenter = container.resolve(TopupApprovalPresenter);
    presenter
      .approveTopupApproval(new ApproveTopupApproval(params.id, params.amount))
      .then(() => {
        this.setSuccessApprove(true);
        this.fetchTopupApprovalList();
      })
      .catch(err => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(
            err,
            "Persetujuan Gagal !",
            () => {
              MainAppController.closeErrorMessage();
              this.approveTopupApproval({
                id: params.id,
                amount: params.amount
              });
            },
            () => this.setShowTopupApprovalForm(true)
          )
        );
      })
      .finally(() => this.setLoadingApprove(false));
  }

  @Action
  public rejectTopupApproval(params: { id: number; reason: string }) {
    this.setLoadingReject(true);
    const presenter = container.resolve(TopupApprovalPresenter);
    presenter
      .rejectTopupApproval(new RejectTopupApproval(params.id, params.reason))
      .then(() => {
        this.setSuccessReject(true);
      })
      .catch(err => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(
            err,
            "Penolakan Gagal !",
            () => {
              MainAppController.closeErrorMessage();
              this.rejectTopupApproval({
                id: params.id,
                reason: params.reason
              });
            },
            () => this.setShowTopupRejectionForm(true)
          )
        );
      })
      .finally(() => this.setLoadingReject(false));
  }

  @Action
  public fetchTopupApprovalList() {
    this.setError(false);
    this.setErrorCause("");
    this.getListTopupApproval({
      page: this.topupApprovalList.pagination.page,
      limit: this.topupApprovalList.pagination.limit,
      search: this.search,
      partnerName: this.advanceFilterData.clientPartnerName,
      status: this.advanceFilterData.topupStatus.value,
      bankName: this.advanceFilterData.bankName.name,
      verifiedBy: this.advanceFilterData.verificationTopupBy.value
    });
  }

  @Action
  public getListTopupApproval(params: {
    page: number;
    limit: number;
    search: string;
    partnerName: string;
    status: string;
    bankName: string;
    verifiedBy: string;
  }) {
    this.setLoading(true);
    const presenter = container.resolve(TopupApprovalPresenter);
    presenter
      .getListTopupApproval(
        params.page,
        params.limit,
        params.search,
        params.partnerName,
        params.status,
        params.bankName,
        params.verifiedBy
      )
      .then(res => {
        this.setTopupApprovalList(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch(err => {
        this.setError(true);
        this.setErrorCause(err.response ? "server" : "internet");
      })
      .finally(() => this.setLoading(false));
  }

  @Action
  public getActorVerifiedList() {
    this.setLoading(true);
    const presenter = container.resolve(TopupApprovalPresenter);
    presenter
      .getActorVerifiedList()
      .then((res: ActorData[]) => {
        this.setActorVerifiedList(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public getDetail(id: number) {
    this.setLoadingDetail(true);
    const presenter = container.resolve(TopupApprovalPresenter);
    presenter
      .getTopupApprovalDetail(id)
      .then((res: TopupApprovalData) => {
        this.setDetailData(res);
        this.setErrorDetail(false);
        this.setErrorCauseDetail("");
      })
      .catch((error: any) => {
        this.setErrorDetail(true);
        this.setErrorCauseDetail(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoadingDetail(false);
      });
  }

  @Mutation
  public setActorVerifiedList(val: ActorData[]) {
    this.actorVerifiedList = val;
  }
  @Mutation
  public setTopupApprovalList(val: TopupApprovalListEntities) {
    this.topupApprovalList = val;
  }

  @Mutation
  public setDetailData(val: TopupApprovalData) {
    this.detailData = val;
  }

  @Mutation
  public setError(val: boolean) {
    this.isError = val;
  }

  @Mutation
  public setShowDetail(val: boolean) {
    this.isShowDetail = val;
  }

  @Mutation
  public setLoadingDetail(val: boolean) {
    this.isLoadingDetail = val;
  }

  @Mutation
  public setErrorDetail(val: boolean) {
    this.isErrorDetail = val;
  }

  @Mutation
  public setErrorCauseDetail(val: string) {
    this.errorCauseDetail = val;
  }

  @Mutation
  public setErrorCause(val: string) {
    this.errorCause = val;
  }

  @Mutation
  public setLoading(val: boolean) {
    this.isLoading = val;
  }

  @Mutation
  public setLoadingApprove(val: boolean) {
    this.isLoadingApprove = val;
  }

  @Mutation
  public setSuccessApprove(val: boolean) {
    this.isSuccessApprove = val;
  }

  @Mutation
  public setLoadingReject(val: boolean) {
    this.isLoadingReject = val;
  }

  @Mutation
  public setSuccessReject(val: boolean) {
    this.isSuccessReject = val;
  }

  @Mutation
  public setSearch(val: string) {
    this.search = val;
  }

  @Mutation
  public setAdvanceFilterData(val: any) {
    this.advanceFilterData = val;
  }

  @Mutation
  public setFirstPage() {
    this.topupApprovalList.pagination.page = 1;
  }

  @Mutation
  public setShowTopupApprovalForm(val: boolean) {
    this.showTopupApprovalForm = val;
  }

  @Mutation
  public setShowTopupRejectionForm(val: boolean) {
    this.showTopupRejectionForm = val;
  }
}

export const TopupApprovalController = getModule(TopupApprovalStore);
