/* eslint-disable @typescript-eslint/camelcase */
import { container } from "tsyringe";
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule
} from "vuex-module-decorators";
import store from "@/store";
import {
  RouteData,
  RouteEntities,
  RouteOptions
} from "@/domain/entities/Route";
import { RoutePresenter } from "../presenters/RoutePresenter";
import { EditRouteApiRequest } from "@/data/payload/api/RouteRequest";
import { Pagination } from "@/domain/entities/Pagination";
import { LocationSearchPresenter } from "../presenters/LocationSearchPresenter";
import { LocationSearchEntities } from "@/domain/entities/LocationSearch";
import { MainAppController } from "./MainAppController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";

export interface RouteState {
  routeData: RouteEntities;
  routeDetailData: RouteData;
  isLoading: boolean;
  isError: boolean;
  openModal: boolean;
  isRoute: boolean;
  locationSearchOriginList: LocationSearchEntities;
  locationSearchDestinationList: LocationSearchEntities;
  selectSearchOrigin: string;
  selectSearchDestination: string;
  locationSearchOriginNotFound: boolean;
  locationSearchDestinationNotFound: boolean;
}

@Module({ namespaced: true, dynamic: true, store, name: "route" })
class RouteStore extends VuexModule implements RouteState {
  public routeData: RouteEntities = new RouteEntities(
    false,
    0,
    "",
    new Pagination(1, 10),
    []
  );
  public routeDetailData = new RouteData(
    0,
    "",
    "",
    0,
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    []
  );
  public routeOption = new RouteOptions("", false, [], false, []);
  public defaultRouteOption = new RouteOptions("", false, [], false, []);
  public isLoading = false;
  public isError = false;
  public openModal = false;
  public isRoute = false;
  public page = 1;
  public limit = 10;
  public search = "";
  public status = "";
  public errorCause = "";
  public locationSearchOriginList = new LocationSearchEntities(
    new Pagination(1, 10),
    []
  );
  public locationSearchDestinationList = new LocationSearchEntities(
    new Pagination(1, 10),
    []
  );
  public selectSearchOrigin = "";
  public selectSearchDestination = "";
  public openSuccess = false;
  public openPopupError = false;
  public locationSearchOriginNotFound = false;
  public locationSearchDestinationNotFound = false;
  public isErrorEdit = false;

  @Mutation
  public setRouteData(data: RouteEntities) {
    this.routeData = data;
  }

  @Mutation
  public setRouteOptionData(data: RouteOptions) {
    this.routeOption = data;
  }

  @Mutation
  public setOptionRouteDefault(data: RouteOptions) {
    this.defaultRouteOption = data;
  }

  @Mutation
  public setRouteDetailData(data: RouteData) {
    const newData: any = data;
    const addIsOpen = newData.route_option.map((e: any) => ({
      ...e,
      isOpen: false
    }));
    this.routeDetailData = { ...data, route_option: addIsOpen };
  }

  @Mutation
  public setLoading(value: boolean) {
    this.isLoading = value;
  }

  @Mutation
  public setError(value: boolean) {
    this.isError = value;
  }

  @Mutation
  public setErrorEdit(boolean: boolean) {
    this.isErrorEdit = boolean;
  }

  @Mutation
  public setOpenModal(value: boolean) {
    this.openModal = value;
  }

  @Mutation
  public setRoute(value: boolean) {
    this.isRoute = value;
  }

  @Mutation
  private setErrorCause(value: string) {
    this.errorCause = value;
  }

  @Mutation
  public setSearch(value: string) {
    this.search = value;
  }

  @Mutation
  public setStatus(value: string) {
    this.status = value;
  }

  @Mutation
  public async nextPage() {
    if (this.page >= 1) {
      this.page = Number(this.page) + 1;
    }
  }

  @Mutation
  public async prevPage() {
    this.page = Number(this.page) - 1;
  }

  @Mutation
  public async setPage(page: number) {
    this.page = page;
  }

  @Mutation
  public async setFirstPage() {
    this.page = 1;
  }

  @Mutation
  public setSelectSearchOrigin(value: string) {
    this.selectSearchOrigin = value;
  }

  @Mutation
  public setSelectSearchDestination(value: string) {
    this.selectSearchDestination = value;
  }
  @Mutation
  private setLocationSearchOriginList(data: LocationSearchEntities) {
    this.locationSearchOriginList = data;
  }

  @Mutation
  private setLocationSearchDestinationList(data: LocationSearchEntities) {
    this.locationSearchDestinationList = data;
  }

  @Mutation
  public setOpenSuccess(value: boolean) {
    this.openSuccess = value;
  }

  @Mutation
  public setOpenPopupError(value: boolean) {
    this.openPopupError = value;
  }

  @Mutation
  public setLocationSearchOriginNotFound(value: boolean) {
    this.locationSearchOriginNotFound = value;
  }
  @Mutation
  public setLocationSearchDestinationNotFound(value: boolean) {
    this.locationSearchDestinationNotFound = value;
  }

  @Action
  public _onGetList(params: {
    page: number;
    limit: number;
    status: string;
    originId: string;
    destinationId: string;
    sortBy: string;
  }) {
    this.setLoading(true);
    const presenter = container.resolve(RoutePresenter);
    presenter
      .getListEmbargo(
        params.page,
        params.limit,
        params.status,
        params.originId,
        params.destinationId,
        params.sortBy
      )
      .then((res: RouteEntities) => {
        this.setRouteData(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public async fetchRouteManagement() {
    this._onGetList({
      page: this.routeData.meta.page,
      limit: this.routeData.meta.limit,
      status: "",
      originId: "",
      destinationId: "",
      sortBy: "asc"
    });
  }

  @Action
  public _onGetDetail(id: number) {
    this.setLoading(true);
    const presenter = container.resolve(RoutePresenter);
    presenter
      .getDetailRoute(id)
      .then((res: RouteData) => {
        this.setRouteDetailData(res);

        // set default option
        const defaultOption: RouteOptions =
          res.route_option?.find(option => option.is_default) ||
          new RouteOptions("", false, [], false, []);
        this.setOptionRouteDefault(defaultOption);

        this.setRoute(
          res.route_status.toLowerCase() === "active" ? true : false
        );
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setRouteDetailData(
          new RouteData(0, "", "", 0, "", "", "", "", "", "", "", "", [])
        );
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public handleError() {
    this.setError(false);
  }

  @Action
  public async setNextPage() {
    await this.nextPage();
    this._onGetList({
      page: this.page,
      limit: 10,
      status: this.status,
      originId: this.selectSearchOrigin,
      destinationId: this.selectSearchDestination,
      sortBy: "asc"
    });
  }

  @Action
  public async setPrevPage() {
    if (this.page !== 1) {
      await this.prevPage();
      this._onGetList({
        page: this.page,
        limit: 10,
        status: this.status,
        originId: this.selectSearchOrigin,
        destinationId: this.selectSearchDestination,
        sortBy: "asc"
      });
    }
  }

  @Action
  public setPageAction(value: number) {
    this.setPage(value);
    this._onGetList({
      page: value,
      limit: 10,
      status: this.status,
      originId: this.selectSearchOrigin,
      destinationId: this.selectSearchDestination,
      sortBy: "asc"
    });
  }

  @Action
  public _onEdit(params: { id: number; isRoute: string }) {
    MainAppController.showLoading();
    const presenter = container.resolve(RoutePresenter);
    presenter
      .edit(
        new EditRouteApiRequest({
          routeId: params.id,
          routeStatus: params.isRoute,
          routeOption: this.routeDetailData.route_option
            ? this.routeDetailData.route_option?.map(item => ({
                groupOptionName: item.group_option_name,
                isActive: item.option_status
              }))
            : []
        })
      )
      .then(() => {
        this.setOpenModal(false);
        this.setOpenSuccess(true);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch(err => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Persetujuan Gagal !", () => {
            MainAppController.closeErrorMessage();
            this._onEdit(params);
          })
        );
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public searchAction() {
    this.setSearch(
      `${this.selectSearchOrigin} - ${this.selectSearchDestination}`
    );
    this._onGetList({
      status: this.status,
      page: 1,
      limit: 10,
      originId: this.selectSearchOrigin,
      destinationId: this.selectSearchDestination,
      sortBy: "asc"
    });
  }

  @Action
  public selectSearchOriginAction(value: string) {
    this.setSelectSearchOrigin(value);
    this._onGetList({
      page: 1,
      limit: 10,
      status: this.status,
      originId: this.selectSearchOrigin,
      destinationId: this.selectSearchDestination,
      sortBy: "asc"
    });
  }

  @Action
  public selectSearchDestinationAction(value: string) {
    this.setSelectSearchDestination(value);
    this._onGetList({
      page: 1,
      limit: 10,
      status: this.status,
      originId: this.selectSearchOrigin,
      destinationId: this.selectSearchDestination,
      sortBy: "asc"
    });
  }

  public isLoadingLocation = false;
  @Mutation
  setIsLoadingLocation(val: boolean) {
    this.isLoadingLocation = val;
  }
  @Action
  public getLocationSearchList(params: {
    type: string;
    page: number;
    limit: number;
    search: string;
  }) {
    this.setIsLoadingLocation(true);
    const presenter = container.resolve(LocationSearchPresenter);
    if (params.search.length > 2) {
      presenter
        .getLocationSearchList(params.page, params.limit, params.search)
        .then((res: LocationSearchEntities) => {
          if (params.type === "origin") {
            this.setLocationSearchOriginList(res);
            if (res.locationSearchData.length > 0) {
              this.setLocationSearchOriginNotFound(false);
            } else {
              this.setLocationSearchOriginNotFound(true);
            }
          } else {
            this.setLocationSearchDestinationList(res);
            if (res.locationSearchData.length > 0) {
              this.setLocationSearchDestinationNotFound(false);
            } else {
              this.setLocationSearchDestinationNotFound(true);
            }
          }
        })
        .finally(() => this.setIsLoadingLocation(false));
    } else if (params.search.length === 0) {
      presenter
        .getLocationSearchList(params.page, params.limit, "jakarta")
        .then((res: LocationSearchEntities) => {
          if (params.type === "origin") {
            this.setLocationSearchOriginList(res);
          } else {
            this.setLocationSearchDestinationList(res);
          }
        })
        .finally(() => this.setIsLoadingLocation(false));
      if (params.type === "origin") {
        this.selectSearchOriginAction("");
      } else {
        this.selectSearchDestinationAction("");
      }
    } else {
      presenter
        .getLocationSearchList(params.page, params.limit, "jakarta")
        .then((res: LocationSearchEntities) => {
          if (params.type === "origin") {
            this.setLocationSearchOriginList(res);
          } else {
            this.setLocationSearchDestinationList(res);
          }
        })
        .finally(() => this.setIsLoadingLocation(false));
    }
  }
}

export const RouteController = getModule(RouteStore);
