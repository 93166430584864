/* eslint-disable @typescript-eslint/camelcase */

import { TopUpVerificationRequest } from "@/data/payload/contracts/TopUpVerificationRequest";
import { TopUpVerificationDetail } from "@/domain/entities/TopupVerification";

export class VerifyTopUpVerification implements TopUpVerificationRequest {
  data: TopUpVerificationDetail;
  constructor(data: TopUpVerificationDetail) {
    this.data = data;
  }
  public toJSON(): string {
    return JSON.stringify({
      tum_id: this.data.id,
      amount: this.data.amount
    });
  }
}

export class RejectTopUpVerification implements TopUpVerificationRequest {
  data: TopUpVerificationDetail;
  constructor(data: TopUpVerificationDetail) {
    this.data = data;
  }
  public toJSON(): string {
    return JSON.stringify({
      tum_id: this.data.id,
      reject_reason: this.data.reasonRejected
    });
  }
}
